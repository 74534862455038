import { scalingFactor } from '@/utils/drawing'
import { isValidNumber } from '@/utils/index'

export function formatBigNumber(n) {
  if (n < 1e5) return parseInt(n * 100) / 100
  if (n >= 1e5 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K'
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M'
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B'
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'
}

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
}

export function sumElements(array, key) {
  if (array.length === 0) {
    return 0
  } else {
    return array.reduce((accumulator, currentValue) => accumulator + currentValue[key], 0)
  }
}

export function getItemNumber(parentIteration, childIteration, arr) {
  const prevParentLayers = arr.slice(0, parentIteration)
  return (
    prevParentLayers.reduce(
      (accumulator, currentValue) => accumulator + currentValue.items.length,
      0
    ) +
    childIteration +
    1
  )
}

export function getFastenerInfo(totalIndex, localIndex, arr, defaultBaseId, isForScheme = false) {
  arr.splice(totalIndex + 1)
  arr[totalIndex].items.splice(localIndex + 1)
  let isBreak = false
  let fastenerHeight = 0
  let fastenerBase = ''
  let collectedLayers = 0

  for (let i = arr.length - 1; i >= 0 && !isBreak; i -= 1) {
    for (let n = arr[i].items.length - 1; n >= 0; n -= 1) {
      if (arr[i].items[n].material.isBaseForFasteners) {
        fastenerBase = arr[i].items[n].material.baseTypeId || arr[i].items[n].baseTypeId
        isBreak = true
        break
      } else {
        const target = arr[i].items[n].material
        if (
          Object.prototype.hasOwnProperty.call(target, 'value') &&
          !isNaN(parseFloat(target.value))
        ) {
          const isImageLayer = arr[i].items[n].isImageLayer || false
          let computedHeight
          if (isForScheme) {
            if (isImageLayer) {
              computedHeight = arr[i].items[n].material.value
            } else {
              computedHeight = arr[i].items[n].material.value * scalingFactor
            }
          } else {
            computedHeight = arr[i].items[n].material.value
          }

          fastenerHeight +=
            !Object.prototype.hasOwnProperty.call(arr[i], 'isEnabled') || arr[i].isEnabled
              ? computedHeight
              : 0
        }
        collectedLayers +=
          !Object.prototype.hasOwnProperty.call(arr[i], 'isEnabled') || arr[i].isEnabled ? 1 : 0
      }
    }
  }
  fastenerBase = fastenerBase !== '' ? fastenerBase : defaultBaseId
  return { fastenerBase, fastenerHeight, collectedLayers }
}

export function isLayerHasFastener(item) {
  return Object.prototype.hasOwnProperty.call(item, 'fasteners')
}

export function isWedgeExist(layers) {
  return layers.some(p => Object.prototype.hasOwnProperty.call(p, 'isSlope'))
}

export function isSomeWedgeIsEnabled(layers) {
  return layers.some(
    p => Object.prototype.hasOwnProperty.call(p, 'isSlope') && p.isEnabled === true
  )
}

export function isSomeFastenerAttachWedge(layers) {
  return layers.some(p =>
    p.items.some(f => Object.prototype.hasOwnProperty.call(f, 'fasteners') && f.fasteners.isAttach)
  )
}

export function isTargetLayerInWedgeExist(layers, target) {
  const filteredLayers = layers.filter(
    p => Object.prototype.hasOwnProperty.call(p, 'isSlope') && p.isEnabled
  )
  return filteredLayers.some(p =>
    p.items.some(f => Object.prototype.hasOwnProperty.call(f, target))
  )
}

export function isLayerHasToggler(layer) {
  // return Object.prototype.hasOwnProperty.call(layer, 'isEnabled')
  return layer.immutable
}

export function isSectorHasSlopes(sector) {
  return Object.prototype.hasOwnProperty.call(sector, 'wedge')
}

export function calculateGlueSquare(contrSlopePlates, mainSlopePlates) {
  const plates = [contrSlopePlates, mainSlopePlates]
  plates.forEach(p => p.reduce((acc, obj) => acc + obj.square, 0))
  return plates
    .map(p => p.reduce((acc, obj) => acc + obj.square * obj.count, 0))
    .reduce((acc, val) => acc + val, 0)
}

export function isSomeWedgeLayerHasMaterial(slopes) {
  return slopes.some(p => p.id !== null)
}

export const isWedgeSquareExistAndValid = wedge => {
  if (wedge.slopes.contrSlope.id !== null) {
    return isValidNumber(wedge.square)
  } else {
    return true
  }
}

export const isWedgeGluValid = (wedge, layers, target) => {
  const isGluExist = isTargetLayerInWedgeExist(layers, target)
  return isGluExist ? isValidNumber(wedge.glue) : true
}

export function getButtonsSetup(itemsLength, index, item, placement, needMultilayerMode = false) {
  const setUp = []
  if (placement === 'system') {
    if (itemsLength > 1) {
      if (index > 0 && index < itemsLength - 1) {
        setUp.push('up', 'down')
      } else if (index === 0) {
        setUp.push('down')
      } else {
        setUp.push('up')
      }
    }
    const isParentLayer = Object.prototype.hasOwnProperty.call(item, 'items')
    const isBaseLayer = Object.prototype.hasOwnProperty.call(item, 'isBaseLayer')

    if (!isBaseLayer) {
      setUp.push('delete')
    }

    if (!isParentLayer) {
      const isLayerCanUseFastener = item.material.isMechanicalFasteners
      const isLayerHasFastener = Object.prototype.hasOwnProperty.call(item, 'fasteners')
      if (isLayerCanUseFastener) {
        isLayerHasFastener ? setUp.push('removeFastener') : setUp.push('addFastener')
      }
      if (needMultilayerMode) {
        setUp.push('multilayer')
      }
    }
  } else {
    if (itemsLength > 1) {
      if (index > 0 && index < itemsLength - 1) {
        setUp.push('up', 'down')
      } else if (index === 0) {
        setUp.push('down')
      } else {
        setUp.push('up')
      }
    }
    setUp.push('delete')
  }
  return setUp
}

export function scrollWithZoom(wrapper, zoom) {
  const realHeight = wrapper.offsetHeight
  const realWidth = wrapper.offsetWidth
  setTimeout(() => {
    wrapper.scrollTo(
      (realWidth * zoom) / 2 - realWidth / 2,
      (realHeight * zoom) / 2 - realHeight / 2
    )
  }, 0)
}

export function formatWord(words, count) {
  const cases = [2, 0, 1, 1, 1, 2]
  return words[count % 100 > 4 && count % 100 < 20 ? 2 : cases[count % 10 < 5 ? count % 10 : 5]]
}

export const isShowElement = material => !(material.isDisabled && material.value === 0)
