import axios from 'axios'
import { cloneDeep } from 'lodash'
import { findJunctionOriginalName, getNameWithCapitalFirstLetter, prepareAlbums } from '@/utils'

export const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? process.env.VUE_APP_API_URL
      : `${location.origin}/service_api/`,
  headers: {
    'Content-Type': 'application/hal+json',
    Accept: 'application/json'
  }
})
export const actionApi = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development' ? process.env.VUE_APP_ACTION_URL : `${location.origin}`,
  headers: {
    'Content-Type': 'application/hal+json',
    Accept: 'application/json'
  }
})
const ratingEndpoint = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? process.env.VUE_APP_RATING_URL
      : `${location.origin}/service_rating/`,
  headers: {
    'Content-Type': 'application/hal+json',
    Accept: 'application/json'
  }
})

export const getContent = lang => {
  const formData = new FormData()
  formData.append('lang', lang)
  return api.post('/content', formData, {
    withCredentials: true
  })
}

export const fetchSlopeMaterials = lang => {
  const formData = new FormData()
  formData.append('lang', lang)
  return api.post('/slope_content', formData)
}

export const deleteDrawing = (files, lang) => {
  const formData = new FormData()
  formData.append('files', files)
  formData.append('lang', lang)
  return api.post('/delete_file', formData)
}

export const uploadDrawing = (image, lang) => {
  const formData = new FormData()
  formData.append('image', image)
  formData.append('lang', lang)
  return api.post('/save_file', formData)
}

export const getResults = (lang, sectors, result) => {
  const formData = new FormData()
  formData.append('lang', lang)
  formData.append('sectors', JSON.stringify(sectors))
  if (result && result?.id) formData.append('result', result.id)
  return api.post('/calculate', formData)
}

export const getSavedProject = (hash, lang) => {
  const formData = new FormData()
  formData.append('data', hash)
  formData.append('lang', lang)
  return api.post('/results_get', formData)
}

export const getPdf = (payload, sectors, stateResult, junctions, stateAlbums, resultId, allAlbums, publicCalculationLink) => {
  const formData = new FormData()
  let data = {}
  formData.append('lang', payload.lang)
  formData.append('type', payload.type)
  if (payload.type === 'total') {
    data.sectors = sectors
    data.result = cloneDeep(stateResult)

    data.result.sectors = data.result.sectors.map((sector, index) => {
      if (!sector?.junctions) {
        return { ...sector }
      }

      const junctions = sector.junctions.map((j, i) => {
        const name = getNameWithCapitalFirstLetter(data.result.sectors[index].sector.junctions[i].originalName)
        const quantity = data.result.total.materials[index].junction[i].qty
        const unit = data.result.total.materials[index].junction[i].unit

        return { functionLayers: [...j], name, quantity, unit }
      })

      return { ...sector, junctions }
    })

    data.albums = prepareAlbums(stateResult.total.albums, allAlbums)
    data.firstPageTitle = ''
  } else {
    const clonedSector = cloneDeep(sectors[payload.sectorIndex])
    clonedSector.junctions.forEach((junction, index) => {
      junction.originalName = findJunctionOriginalName(index, clonedSector.junctions, junctions)
    })

    data.sector = clonedSector
    data.result = {
      ...stateResult.sectors[payload.sectorIndex],
      note: stateResult.note,
      disclaimer: stateResult.disclaimer
    }

    if (data.result.junctions && data.result.junctions?.length) {
      data.result.junctions = data.result.junctions.map((j, index) => {
        const name = getNameWithCapitalFirstLetter(data.result.sector.junctions[index].originalName)

        const quantity = stateResult.total.materials[payload.sectorIndex].junction[index].qty
        const unit = stateResult.total.materials[payload.sectorIndex].junction[index].unit

        return { functionLayers: [...j], name, quantity, unit }
      })
    }

    const systemMaterials = stateResult.total.materials[payload.sectorIndex].system
      .filter(obj => obj?.materials)
      .map(obj => obj.materials)
      .flat()
    const junctionsMaterials = stateResult.total.materials[payload.sectorIndex].junction
      ? stateResult.total.materials[payload.sectorIndex].junction
        .filter(obj => obj?.materials)
        .map(obj => obj.materials)
        .flat(2)
        .map(obj => obj.materials)
        .flat()
      : []

    data.resultBySector = [...systemMaterials, ...junctionsMaterials]
    if (payload.type === 'sector') {
      data.albums = prepareAlbums(stateResult.sectors[payload.sectorIndex].albums, allAlbums)
      data.firstPageTitle = `${payload.pageTitle} "${payload.header.name}"`
    }
    if (payload.type === 'junction') {
      data.firstPageTitle = `${payload.pageTitle} "${payload.header.name}"`
      data.junctionResult =
        stateResult.sectors[payload.sectorIndex].junctions[payload.junctionIndex]
      data.junctionResultTotal =
          Object.values(stateResult.sectors[payload.sectorIndex].junctionsMaterials[payload.junctionIndex]).flat()
      data.albums = prepareAlbums(
        stateResult.sectors[payload.sectorIndex].junctionsAlbums[payload.junctionIndex],
        allAlbums
      )
    }
  }
  if (publicCalculationLink) {
    data.link = publicCalculationLink
  }
  data.header = payload.header
  data.year = new Date().getFullYear()
  data.domenName = `${payload.domenName}/result/?object=`
  formData.append('data', JSON.stringify(data))
  if (resultId) formData.append('result', resultId)

  return api.post('/pdf', formData, {
    responseType: 'blob' // Force to receive data in a Blob Format
  })
}

export const getXls = (lang, type, result, sectors, resultId, sectorIndex, junctionIndex, title, publicCalculationLink) => {
  const formData = new FormData()
  formData.append('lang', lang)
  formData.append('type', type)
  formData.append('data', JSON.stringify(result))
  formData.append('sectors', JSON.stringify(sectors))
  if (sectorIndex) {
    formData.append('sectorIndex', sectorIndex)
  }
  if (junctionIndex) {
    formData.append('junctionIndex', junctionIndex)
  }
  if (title) {
    formData.append('title', title)
  }
  if (resultId) formData.append('result', resultId)
  if (publicCalculationLink) formData.append('info', JSON.stringify({ link: publicCalculationLink }))

  return api.post('/excel', formData, {
    responseType: 'blob'
  })
}

export const importFromExcel = (lang, table, basement, mount) => {
  const formData = new FormData()
  formData.append('lang', lang)
  formData.append('data', JSON.stringify(table))
  formData.append('basement', basement)
  formData.append('mount', mount)
  return api.post('/excel_import', formData)
}

export const importWedgeFromLink = (lang, link) => {
  const formData = new FormData()
  formData.append('lang', lang)
  formData.append('link', link)
  return api.post('/calc_import', formData)
}

export const getItem = (lang, type, id, variables = null) => {
  const formData = new FormData()
  formData.append('lang', lang)
  formData.append('type', type)
  formData.append('id', id)
  if (variables) formData.append('variables', JSON.stringify(variables))
  return api.post('/get_item', formData)
}

export const getMaterialsForLayer = (
  lang,
  type,
  layerIndex,
  itemIndex,
  setup,
  id,
  mechanicalMount
) => {
  const formData = new FormData()
  formData.append('lang', lang)
  formData.append('type', type)
  if (layerIndex !== null) {
    formData.append('layer', layerIndex)
  }
  if (itemIndex !== null) {
    formData.append('item', itemIndex)
  }
  formData.append('setup', setup)
  formData.append('id', id)
  formData.append('mechanicalMount', mechanicalMount ? '1' : '0')
  return api.post('/get_materials', formData)
}

export const sendRating = (object, lang, hash) => {
  const formData = new FormData()
  formData.append('data', JSON.stringify(object))
  formData.append('lang', lang)
  if (hash) {
    formData.append('hash', hash)
  }
  return ratingEndpoint.post('/vote', formData)
}

export const searchMaterial = ({ lang, type, query, layerId }) => {
  const formData = new FormData()
  formData.append('lang', lang)
  formData.append('query', query)
  formData.append('type', type)
  if (layerId !== null) {
    formData.append('layerId', layerId)
  }
  return api.post('/materials_search', formData)
}

export const getLayersListByMaterial = ({ lang, type, layerId, materialId }) => {
  const formData = new FormData()
  formData.append('lang', lang)
  formData.append('type', type)
  formData.append('materialID', materialId)
  if (layerId !== null) {
    formData.append('layerId', layerId)
  }
  return api.post('/layers_search', formData)
}
export const checkTNToken = (token, lang) => {
  let formData = new FormData()
  formData.append('token', token)
  formData.append('lang', lang)
  return api.post('/token_status', formData)
}
